import { lazy, useContext } from "react";
import { role_id } from "../common/constants";
import { RouteText } from "../common/text.json";
import Login from "../containers/Auth/Login";
import { AuthContext } from "../containers/AuthProvider";

const Index = lazy(() => import("../containers/Admin/index"));
const Display = lazy(() =>
  import("../containers/Admin/Category/Display/Display")
);
const RegionList = lazy(() =>
  import("../containers/Admin/Category/Region/List")
);
const DevoteeTypeList = lazy(() =>
  import("../containers/Admin/Category/DevoteeType/List")
);
const DevoteeTypeForm = lazy(() =>
  import("../containers/Admin/Category/DevoteeType/Form")
);
const DevoteeTypeImport = lazy(() =>
  import("../containers/Admin/Category/DevoteeType/Import")
);
const UserList = lazy(() => import("../containers/Admin/Users/List"));
const UserForm = lazy(() => import("../containers/Admin/Users/Form"));
const RoleList = lazy(() => import("../containers/Admin/Role/List"));
const RoleForm = lazy(() => import("../containers/Admin/Role/Form"));
const NdPlaceList = lazy(() => import("../containers/Admin/Category/NdPlace/List"));
const RankList = lazy(() => import("../containers/Admin/Category/Rank/List"));
const RankImport = lazy(() =>
  import("../containers/Admin/Category/Rank/Import")
);
const WorkUnitList = lazy(() =>
  import("../containers/Admin/Category/WorkUnit/List")
);
const WorkUnitImport = lazy(() =>
  import("../containers/Admin/Category/WorkUnit/Import")
);
const BattlefieldList = lazy(() =>
  import("../containers/Admin/Category/Battelfield/List")
);
const BattlefieldImport = lazy(() =>
  import("../containers/Admin/Category/Battelfield/Import")
);
const EthnicList = lazy(() =>
  import("../containers/Admin/Category/Ethnic/List")
);
const EthnicImport = lazy(() =>
  import("../containers/Admin/Category/Ethnic/Import")
);
const OriginalRegionList = lazy(() =>
  import("../containers/Admin/Category/OriginalRegion/List")
);
const OriginalRegionForm = lazy(() =>
  import("../containers/Admin/Category/OriginalRegion/Form")
);
const RelativeTypeList = lazy(() =>
  import("../containers/Admin/Category/RelativeType/List")
);
const WelfareRegimeTypeList = lazy(() =>
  import("../containers/Admin/Category/WelfareRegimeType/List")
);
const ReligionList = lazy(() =>
  import("../containers/Admin/Category/Religion/List")
);
const GDDTList = lazy(() => import("../containers/Admin/Category/TCGD/List"));
const MedalList = lazy(() => import("../containers/Admin/Category/Medal/List"));
const DocumentTypeList = lazy(() =>
  import("../containers/Admin/Category/DocumentType/List")
);
const DocumentTypeImport = lazy(() =>
  import("../containers/Admin/Category/DocumentType/Import")
);
const DocumentTypeForm = lazy(() =>
  import("../containers/Admin/Category/DocumentType/Form")
);
const PositionList = lazy(() =>
  import("../containers/Admin/Category/Position/List")
);
const PositionImport = lazy(() =>
  import("../containers/Admin/Category/Position/Import")
);
const WelfareRegimeTypeImport = lazy(() =>
  import("../containers/Admin/Category/WelfareRegimeType/Import")
);
const RelativeTypeImport = lazy(() =>
  import("../containers/Admin/Category/RelativeType/Import")
);
const OriginalRegionImport = lazy(() =>
  import("../containers/Admin/Category/OriginalRegion/Import")
);
const ReligionImport = lazy(() =>
  import("../containers/Admin/Category/Religion/ImportExcel")
);
const MedalImport = lazy(() =>
  import("../containers/Admin/Category/Medal/Import")
);
const FileTypeList = lazy(() =>
  import("../containers/Admin/Category/FileType/List")
);
const FileTypeForm = lazy(() =>
  import("../containers/Admin/Category/FileType/Form")
);
const WelfareRegimeList = lazy(() =>
  import("../containers/Admin/WelfareRegimesDevotee/WelfareRegime/List")
);
const WelfareRegimeForm = lazy(() =>
  import("../containers/Admin/WelfareRegimesDevotee/WelfareRegime/Form")
);
const WelfareRegimeImport = lazy(() =>
  import(
    "../containers/Admin/WelfareRegimesDevotee/WelfareRegime/ImportWelfare"
  )
);
const RegionImport = lazy(() =>
  import("../containers/Admin/Category/Region/Import")
);
const DevoteeTypeFileList = lazy(() =>
  import("../containers/Admin/ProfileSetting/DevoteeFileType/List")
);
const RelativeTypeFileList = lazy(() =>
  import("../containers/Admin/ProfileSetting/RelativeFileType/List")
);
const DevoteeTypeFileForm = lazy(() =>
  import("../containers/Admin/ProfileSetting/DevoteeFileType/Form")
);
const RelativeTypeFileForm = lazy(() =>
  import("../containers/Admin/ProfileSetting/RelativeFileType/Form")
);

const PersonList = lazy(() =>
  import("../containers/Admin/Devotee/PersonInfo/List")
);

const DevoteeList = lazy(() =>
  import("../containers/Admin/Devotee/devoteeInfo/List")
);

const RelativeList = lazy(() =>
  import("../containers/Admin/Devotee/devoteeInfo/List")
);

const PersonForm = lazy(() =>
  import("../containers/Admin/Devotee/PersonInfo/Form")
);
const DevoteeTabPanel = lazy(() =>
  import("../containers/Admin/Devotee/devoteeInfo/TabPanel")
);
const DevoteeRelativeForm = lazy(() =>
  import("../containers/Admin/Devotee/DevoteeRelative/Form")
);
const DevoteeRelativeList = lazy(() =>
  import("../containers/Admin/Devotee/DevoteeRelative/List")
);
const DevoteeRelativeCreateForm = lazy(() =>
  import(
    "../containers/Admin/Devotee/DevoteeRelative/DevoteeRelativeCreateForm"
  )
);
const PersonImport = lazy(() =>
  import("../containers/Admin/Devotee/PersonInfo/ImportExcel/ImportExcel")
);

const Setting = lazy(() => import("../containers/Admin/Setting/Setting"));

const DecisionForm = lazy(() =>
  import("../containers/Admin/Devotee/devoteeInfo/Form")
);

const PersonTabPanel = lazy(() =>
  import("../containers/Admin/Devotee/PersonInfo/TabPanel")
);
const AuditList = lazy(() => import("../containers/Admin/Audit/AuditList"));
const RelativeFileList = lazy(() =>
  import("../containers/Admin/Files/RelativeFile/List")
);
const RelativeFileForm = lazy(() =>
  import("../containers/Admin/Files/RelativeFile/Form")
);
const CemateryList = lazy(() =>
  import("../containers/Admin/Category/Cematery/List")
);
const CemateryImport = lazy(() =>
  import("../containers/Admin/Category/Cematery/Import")
);
const ImportDevotee = lazy(() =>
  import("../containers/Admin/Devotee/devoteeInfo/ImportExcel/ImportExcel")
);
const DocForm = lazy(() =>
  import("../containers/Admin/Devotee/DevoteeRelative/Document/Form")
);
const ImportRelative = lazy(() =>
  import("../containers/Admin/Devotee/DevoteeRelative/ImportExcel/ImportExcel")
);
const HelperFile = lazy(() =>
  import("../containers/Admin/Category/HelperFile/HelperFile")
);

const DevoteePay = lazy(() =>
  import("../containers/Admin/PeopleApprove/DevoteeFile/List")
);

const DevoteePayForm = lazy(() =>
  import("../containers/Admin/PeopleApprove/DevoteeFile/Form")
);

const RelativePay = lazy(() =>
  import("../containers/Admin/PeopleApprove/RelativeFile/List")
);

const RelativePayForm = lazy(() =>
  import("../containers/Admin/PeopleApprove/RelativeFile/Form")
);
const ReportChart = lazy(() =>
  import("../containers/Admin/Report/ReportScreen")
);
const ReportInOutInMonth = lazy(() =>
  import("../containers/Admin/Report/ReportInOutInMonth")
);
const DevoteeFileLists = lazy(() =>
  import("../containers/Admin/Files/DevoteeFile/List")
);
const DevoteeFileCreate = lazy(() =>
  import("../containers/Admin/Files/DevoteeFile/Form")
);
const PeopleAccountList = lazy(() =>
  import("../containers/Admin/PeopleAccount/List")
);

const NormalReport = lazy(() =>
  import("../containers/Admin/NormalReport/NormalReport")
);

const PayFileReport = lazy(() =>
  import("../containers/Admin/PayFileReport/PayFileReport")
);

const PayFileReportInMonth = lazy(() =>
  import("../containers/Admin/PayFileReport/PayFileReportInMonth")
);

const PayFileReportByAccount = lazy(() =>
  import("../containers/Admin/PayFileReport/PayFileReportByAccount")
);

const DataSynchronizationList = lazy(() =>
  import("../containers/Admin/DataSynchronization/DataSynchronizationList")
);

const GenderAndAgeReport = lazy(() =>
  import("../containers/Admin/GenderAndAgeReport/PageReport")
);

const FormExportReport = lazy(() =>
  import("../containers/Admin/FormExportReport/ContainerExport")
);

const FormExport1 = lazy(() =>
  import("../containers/Admin/FormExportReport/Form/Form1")
);
const FormExport2 = lazy(() =>
  import("../containers/Admin/FormExportReport/Form/Form2")
);
const FormExport3 = lazy(() =>
  import("../containers/Admin/FormExportReport/Form/Form3")
);
const FormExport4 = lazy(() =>
  import("../containers/Admin/FormExportReport/Form/Form4")
);
const FormExport81 = lazy(() =>
  import("../containers/Admin/FormExportReport/Form/Form8_1")
);
const FormOrthopedic = lazy(() =>
  import("../containers/Admin/FormExportReport/Form/FormOrthopedic")
);

const FormExport82 = lazy(() =>
  import("../containers/Admin/FormExportReport/Form/Form8_2")
);

const FormExport6 = lazy(() =>
  import("../containers/Admin/FormExportReport/Form/Form6")
);

const FormDD = lazy(() => 
  import("../containers/Admin/FormExportReport/Form/FormDD")
);

const DefaultAccount = lazy(() =>
  import("../containers/Admin/DefaultAccount/DefaultAccount")
);

const NewspaperList = lazy(() =>
  import("../containers/Admin/Category/Newspaper/List")
);
const NewspaperForm = lazy(() =>
  import("../containers/Admin/Category/Newspaper/Form")
);

const DayOff = lazy(() => import("../containers/Admin/Category/DayOff/DayOff"));

const OverDateReport = lazy(() =>
  import("../containers/Admin/Report/ReportPayFileDate/ReportContain")
);

const AuthorityPayFile = lazy(() =>
  import("../containers/Admin/Authority/AuthorityPayFile")
);
const NurtureSubsidyReportCpn = lazy(() =>
  import("../containers/Admin/NurtureSubsidyReport/NurtureSubsidyReport")
);

const MonthlyWelfareRegime = lazy(() =>
  import("../containers/Admin/Category/MonthlyWelfareRegime/List")
);

const GravesList = lazy(() => import("../containers/Admin/ADNData/GravesList"));
const MartyrsList = lazy(() =>
  import("../containers/Admin/ADNData/MartyrsList")
);

export const adminPrefix = "";
export const adminApi = "/admin";

export function LoginRoute() {
  return [
    {
      path: "/resetPassword/:token",
      component: Login,
      perm: "",
    },
  ];
}

export function AdminRoutes() {
  const { admin } = useContext(AuthContext);
  const adminRoutes = [
    {
      path: "/",
      component: Index,
      protected: true,
      perm: "",
      name: RouteText.home,
    },
    {
      path: "/testDevoteeFileList",
      component: DevoteeFileLists,
      protected: true,
      perm: "",
      name: "Devotee file list",
    },
    {
      path: "/testDevoteeFileCreate",
      component: DevoteeFileCreate,
      protected: true,
      perm: "",
      name: "Devotee file create",
    },
    {
      path: "/devoteePay",
      component: DevoteePay,
      protected: true,
      perm: "",
      name: "Danh sách hỗ trợ giải quyết chế độ người có công",
    },
    {
      path: "/devoteePay/:id",
      component: DevoteePayForm,
      protected: true,
      perm: "",
      name: "Chỉnh sửa hỗ trợ giải quyết chế độ người có công",
    },
    {
      path: "/relativePay",
      component: RelativePay,
      protected: true,
      perm: "",
      name: "Danh sách hỗ trợ giải quyết chế độ thân nhân người có công",
    },
    {
      path: "/relativePay/:id",
      component: RelativePayForm,
      protected: true,
      perm: "",
      name: "Chỉnh sửa hỗ trợ giải quyết chế độ thân nhân người có công",
    },
    {
      path: "/display",
      component: Display,
      protected: true,
      perm: "display_setting",
      name: RouteText.display,
    },
    {
      path: "/helperFile",
      component: HelperFile,
      protected: true,
      perm: "file_setting",
      name: RouteText.helperFile,
    },
    {
      path: "/region",
      component: RegionList,
      protected: true,
      perm: "region_list",
      name: RouteText.region,
    },
    {
      path: "/devoteeType",
      component: DevoteeTypeList,
      protected: true,
      perm: "devotee_type_list",
      name: RouteText.devotee_type,
    },
    {
      path: "/devoteeType/create",
      component: DevoteeTypeForm,
      protected: true,
      perm: "",
      name: RouteText.devotee_type_create,
    },
    {
      path: "/devoteeType/:id",
      component: DevoteeTypeForm,
      protected: true,
      perm: "",
      name: RouteText.devotee_type_update,
    },
    {
      path: "/devoteeType/upload",
      component: DevoteeTypeImport,
      protected: true,
      perm: "",
      name: RouteText.devotee_type_upload,
    },
    {
      path: "/user",
      component: UserList,
      protected: true,
      perm: "user_list",
      name: RouteText.user_list,
    },
    {
      path: "/user/create",
      component: UserForm,
      protected: true,
      perm: "user_create",
      name: RouteText.user_create,
    },
    {
      path: "/user/:id",
      component: UserForm,
      protected: true,
      perm: "user_view",
      name: RouteText.user_update,
    },
    {
      path: "/ethnic",
      component: EthnicList,
      protected: true,
      name: RouteText.ethnic,
      perm: "ethnic_list",
    },
    {
      path: "/ethnic/upload",
      component: EthnicImport,
      protected: true,
      name: RouteText.upload,
    },
    {
      path: "/originalRegion",
      component: OriginalRegionList,
      protected: true,
      name: RouteText.original_region,
      perm: "original_list",
    },
    {
      path: "/originalRegion/upload",
      component: OriginalRegionImport,
      protected: true,
      name: RouteText.original_region_upload,
    },
    {
      path: "/originalRegion/create",
      component: OriginalRegionForm,
      protected: true,
      name: RouteText.original_region_create,
      perm: "original_create",
    },
    {
      path: "/originalRegion/:id",
      component: OriginalRegionForm,
      protected: true,
      name: RouteText.original_region_update,
      perm: "original_view",
    },
    {
      path: "/region/upload",
      component: RegionImport,
      protected: true,
      name: RouteText.region_upload,
    },
    {
      path: "/role",
      component: RoleList,
      protected: true,
      perm: "role_list",
      name: RouteText.role_list,
    },
    {
      path: "/role/create",
      component: RoleForm,
      protected: true,
      perm: "role_create",
      name: RouteText.role_create,
    },
    {
      path: "/role/:id",
      component: RoleForm,
      protected: true,
      perm: "role_view",
      name: RouteText.role_update,
    },
    {
      path: "/relativeType",
      component: RelativeTypeList,
      protected: true,
      name: RouteText.relative_type,
      perm: "relative_type_list",
    },
    {
      path: "/relativeType/upload",
      component: RelativeTypeImport,
      protected: true,
      name: RouteText.upload,
    },
    {
      path: "/welfareRegimeType",
      component: WelfareRegimeTypeList,
      protected: true,
      name: RouteText.welfare_regime_type,
      perm: "welfare_regime_type_list",
    },
    {
      path: "/religion",
      component: ReligionList,
      protected: true,
      name: RouteText.religion,
      perm: "religion_list",
    },
    {
      path: "/gddt",
      component: GDDTList,
      protected: true,
      name: "Trợ cấp giáo dục",
      perm: "",
    },
    {
      path: "/cematery",
      component: CemateryList,
      protected: true,
      name: RouteText.cematery,
      perm: "cematery_list",
    },
    {
      path: "/newspaper",
      component: NewspaperList,
      protected: true,
      name: "Bài viết",
      perm: "",
    },
    {
      path: "/newspaper/create",
      component: NewspaperForm,
      protected: true,
      name: "Thêm mới bài viết",
      perm: "",
    },
    {
      path: "/newspaper/:id",
      component: NewspaperForm,
      protected: true,
      name: "Chỉnh sửa bài viết",
      perm: "",
    },
    {
      path: "/cematery/upload",
      component: CemateryImport,
      protected: true,
      name: RouteText.upload,
      perm: "cematery_create",
    },
    {
      path: "/medal",
      component: MedalList,
      protected: true,
      name: RouteText.medal,
      perm: "medal_list",
    },
    {
      path: "/monthlyWelfareRegime",
      component: MonthlyWelfareRegime,
      protected: true,
      name: "Trợ cấp hàng tháng",
      perm: "monthly_welfare_regime_list",
    },
    {
      path: "/welfareRegimeType/upload",
      component: WelfareRegimeTypeImport,
      protected: true,
      name: RouteText.upload,
    },
    {
      path: "/documentType",
      component: DocumentTypeList,
      protected: true,
      name: RouteText.document_type,
      perm: "document_type_list",
    },
    {
      path: "/documentType/upload",
      component: DocumentTypeImport,
      protected: true,
      name: RouteText.document_type_upload,
    },
    {
      path: "/documentType/create",
      component: DocumentTypeForm,
      protected: true,
      name: RouteText.document_type_create,
      perm: "document_type_create",
    },
    {
      path: "/documentType/:id",
      component: DocumentTypeForm,
      protected: true,
      name: RouteText.document_type_update,
      perm: "document_type_view",
    },
    {
      path: "/rank",
      component: RankList,
      protected: true,
      name: RouteText.rank,
      perm: "rank_list",
    },
    {
      path: "/ndPlace",
      component: NdPlaceList,
      protected: true,
      name: "Địa điểm điều dưỡng",
      perm: "",
    },
    {
      path: "/rank/upload",
      component: RankImport,
      protected: true,
      name: RouteText.rank_upload,
    },
    {
      path: "/work-unit",
      component: WorkUnitList,
      protected: true,
      name: RouteText.work_unit,
      perm: "work_unit_list",
    },
    {
      path: "/work-unit/upload",
      component: WorkUnitImport,
      protected: true,
      name: RouteText.work_unit_upload,
    },
    {
      path: "/battlefield",
      component: BattlefieldList,
      protected: true,
      name: "Danh sách chiến trường",
      perm: "work_unit_list",
    },
    {
      path: "/battlefield/upload",
      component: BattlefieldImport,
      protected: true,
      name: "Tải lên danh sách chiến trường",
    },
    {
      path: "/position",
      component: PositionList,
      protected: true,
      name: RouteText.position,
      perm: "position_list",
    },
    {
      path: "/position/upload",
      component: PositionImport,
      protected: true,
      name: RouteText.position_upload,
    },
    {
      path: "/religion/upload",
      component: ReligionImport,
      protected: true,
      name: RouteText.upload,
    },
    {
      path: "/medal/upload",
      component: MedalImport,
      protected: true,
      name: RouteText.upload,
    },
    {
      path: "/fileType",
      component: FileTypeList,
      protected: true,
      name: RouteText.file_type_list,
      perm: "file_type_list",
    },
    {
      path: "/fileType/create",
      component: FileTypeForm,
      protected: true,
      name: RouteText.file_type_create,
      perm: "file_type_create",
    },
    {
      path: "/fileType/:id",
      component: FileTypeForm,
      protected: true,
      name: RouteText.file_type_update,
      perm: "file_type_view",
    },
    {
      path: "/welfare_regime",
      component: WelfareRegimeList,
      protected: true,
      name: RouteText.welfare_regime,
      perm: "welfare_regime_list",
    },
    {
      path: "/welfare_regime/upload",
      component: WelfareRegimeImport,
      protected: true,
      name: RouteText.upload,
    },
    {
      path: "/welfare_regime/create",
      component: WelfareRegimeForm,
      protected: true,
      name: RouteText.welfare_regime_create,
      perm: "welfare_regime_create",
    },
    {
      path: "/welfare_regime/:id",
      component: WelfareRegimeForm,
      protected: true,
      name: RouteText.welfare_regime_update,
      perm: "welfare_regime_view",
    },
    {
      path: "/devoteeTypeFile",
      component: DevoteeTypeFileList,
      protected: true,
      name: RouteText.devotee_file_type,
      perm: "devotee_file_list",
    },
    {
      path: "/devoteeTypeFile/create",
      component: DevoteeTypeFileForm,
      protected: true,
      name: RouteText.devotee_file_type_create,
      perm: "devotee_file_create",
    },
    {
      path: "/devoteeTypeFile/:id",
      component: DevoteeTypeFileForm,
      protected: true,
      name: RouteText.devotee_file_type_update,
      perm: "devotee_file_view",
    },
    {
      path: "/relativeTypeFile",
      component: RelativeTypeFileList,
      protected: true,
      name: RouteText.relative_file_type,
      perm: "relative_file_list",
    },
    {
      path: "/relativeTypeFile/:id",
      component: RelativeTypeFileForm,
      protected: true,
      name: RouteText.relative_file_type_update,
      perm: "relative_file_view",
    },
    {
      path: "/personal-information",
      component: PersonList,
      protected: true,
      name: RouteText.person_list,
      perm: "personal_information_list",
    },
    {
      path: "/personal-information/upload",
      component: PersonImport,
      protected: true,
      name: RouteText.upload,
    },
    {
      path: "/personal-information/create",
      component: PersonForm,
      protected: true,
      name: RouteText.person_create,
      perm: "personal_information_create",
    },
    {
      path: "/personal-information/:id",
      component: PersonTabPanel,
      protected: true,
      name: RouteText.person_update,
      perm: "personal_information_view",
    },
    {
      path: "/devotee-information",
      component: DevoteeList,
      protected: true,
      name: RouteText.devotee_list,
      perm: "devotee_information_list",
    },
    {
      path: "/graves",
      component: GravesList,
      protected: true,
      name: "Mộ liệt sĩ",
      perm: "devotee_information_list",
    },
    {
      path: "/martyrs",
      component: MartyrsList,
      protected: true,
      name: "Liệt sĩ",
      perm: "devotee_information_list",
    },
    {
      path: "/devotee-relative-information",
      component: RelativeList,
      protected: true,
      name: RouteText.relative_list,
      perm: "relative_information_list",
    },
    {
      path: "/devotee-information/create",
      component: DecisionForm,
      protected: true,
      perm: "devotee_information_create",
      name: RouteText.devotee_create,
    },
    {
      path: "/devotee-information/upload",
      component: ImportDevotee,
      protected: true,
      perm: "",
      name: RouteText.devotee_upload,
    },
    {
      path: "/devotee-information/:id",
      component: DevoteeTabPanel,
      protected: true,
      perm: "devotee_information_view",
      name: RouteText.devotee_update,
    },
    {
      path: "/devotee-relatives",
      component: DevoteeRelativeList,
      protected: true,
      perm: "relative_information_list",
      name: RouteText.devotee_relatives,
    },
    {
      path: "/devotee-relatives/upload",
      component: ImportRelative,
      protected: true,
      perm: "",
      name: RouteText.relative_upload,
    },
    {
      path: "/devotee-relatives/create",
      component: DevoteeRelativeCreateForm,
      protected: true,
      perm: "relative_information_create",
      name: RouteText.devotee_relatives_create,
    },
    {
      path: "/devotee-relatives/:id",
      component: DevoteeRelativeForm,
      protected: true,
      perm: "relative_information_view",
      name: RouteText.devotee_relatives_update,
    },
    {
      path: "/devotee-relatives/:id/createDoc",
      component: DocForm,
      protected: true,
      perm: "",
      name: RouteText.create_doc,
    },
    {
      path: "/setting",
      component: Setting,
      protected: true,
      name: RouteText.setting,
      perm: "setting_edit",
    },
    {
      path: "/audit",
      component: AuditList,
      name: RouteText.audit,
      perm: "audit_list",
    },
    {
      path: "/devoteeFileList",
      component: DevoteeFileLists,
      protected: true,
      perm: "",
      name: "Tất cả hồ sơ người có công",
    },
    {
      path: "/devoteeFileList/create",
      component: DevoteeFileCreate,
      protected: true,
      perm: "",
      name: "Tạo hồ sơ người có công",
    },
    {
      path: "/devoteeFileList/:id/",
      component: DevoteeFileCreate,
      protected: true,
      perm: "",
      name: "Thông tin hồ sơ người có công",
    },
    {
      path: "/relativeFileList",
      component: RelativeFileList,
      protected: true,
      perm: "",
      name: "Tất cả hồ sơ thân nhân NCC",
    },
    {
      path: "/relativeFileList/create",
      component: RelativeFileForm,
      protected: true,
      perm: "",
      name: "Thêm hồ sơ thân nhân NCC mới",
    },
    {
      path: "/relativeFileList/:id",
      component: RelativeFileForm,
      protected: true,
      perm: "",
      name: "Thông tin hồ sơ thân nhân NCC",
    },
    {
      path: "/reportChart",
      component: ReportChart,
      protected: true,
      perm: "",
      name: "Thống kê",
    },
    {
      path: "/reportInOutInMonth",
      component: ReportInOutInMonth,
      protected: true,
      perm: "",
      name: "Thống kê tăng giảm theo tháng",
    },
    {
      path: "/normalReport",
      component: NormalReport,
      protected: true,
      perm: "",
      name: "Báo cáo/Thống kê NCC",
    },
    {
      path: "/people-account",
      component: PeopleAccountList,
      protected: true,
      perm: "",
      name: "Tài khoản người dân",
    },
    {
      path: "/pay-file-report",
      component: PayFileReport,
      protected: true,
      perm: "",
      name: "Thống kê hồ sơ giải quyết chế độ",
    },
    {
      path: "/pay-file-report-in-month",
      component: PayFileReportInMonth,
      protected: true,
      perm: "",
      name: "Thống kê hồ sơ giải quyết chế độ theo tháng",
    },
    {
      path: "/pay-file-report-by-account",
      component: PayFileReportByAccount,
      protected: true,
      perm: "",
      name: "Thống kê hồ sơ giải quyết chế độ theo người giải quyết",
    },
    {
      path: "/data-synchronization",
      component: DataSynchronizationList,
      protected: true,
      perm: "",
      name: "Đồng bộ dữ liệu",
      hide:
        admin?.role_id != role_id?.ADMIN &&
        admin?.role_id != role_id?.DEPARTMENT_ALL,
    },
    {
      path: "/gender-and-age-report",
      component: GenderAndAgeReport,
      protected: true,
      perm: "",
      name: "Thống kê NCC theo giới tính và độ tuổi",
    },
    {
      path: "/form-export-report",
      component: FormExportReport,
      protected: true,
      perm: "",
      name: "Tải xuống biểu mẫu",
    },
    {
      path: "/form-export-1",
      component: FormExport1,
      protected: true,
      perm: "",
      name:
        "Báo cáo số lượng đối tượng đang hưởng trợ cấp, phụ cấp ưu đãi hàng tháng",
    },
    {
      path: "/form-export-2",
      component: FormExport2,
      protected: true,
      perm: "",
      name:
        "Báo cáo số lượng đối tượng thương binh, thương binh B, người hưởng chính sách như thương binh đang hưởng trợ cấp tháng",
    },
    {
      path: "/form-export-3",
      component: FormExport3,
      protected: true,
      perm: "",
      name: "Báo cáo số lượng đối tượng hưởng trợ cấp ưu đãi giáo dục",
    },
    {
      path: "/form-export-4",
      component: FormExport4,
      protected: true,
      perm: "",
      name: "Báo cáo số lượng đối tượng được mua bảo hiểm y tế",
    },
    {
      path: "/form-export-8-2",
      component: FormExport82,
      protected: true,
      perm: "",
      name: "Quà tặng CTN 27/7",
    },
    {
      path: "/form-export-8-1",
      component: FormExport81,
      protected: true,
      perm: "",
      name: "Quà tặng CTN Tết",
    },
    {
      path: "/form-export-6",
      component: FormExport6,
      protected: true,
      perm: "",
      name:
        "Báo cáo số lượng đối tượng đang được nuôi dưỡng tại cơ sở nuôi dưỡng",
    },
    {
      path: "/form-export-orthopedic-tools",
      component: FormOrthopedic,
      protected: true,
      perm: "",
      name: "Báo cáo trang cấp dụng cụ chỉnh hình",
    },
    {
      path: "/form-export-dd",
      component: FormDD,
      protected: true,
      perm: "",
      name: "Báo cáo trợ cấp điều dưỡng",
    },
    {
      path: "/default-account",
      component: DefaultAccount,
      protected: true,
      perm: "",
      name: "Cài đặt tài khoản tiếp nhận",
    },
    {
      path: "/day-off",
      component: DayOff,
      protected: true,
      perm: "",
      name: "Cài đặt ngày nghỉ",
    },
    {
      path: "/over-date-pay-file",
      component: OverDateReport,
      protected: true,
      perm: "",
      name: "Báo cáo giải quyết chế độ",
    },
    {
      path: "/authority-pay-file",
      component: AuthorityPayFile,
      protected: true,
      perm: "",
      name: "Ủy quyền giải quyết hồ sơ",
    },
    {
      path: "/nurture-subsidies-ltcm",
      component: NurtureSubsidyReportCpn,
      perm: "",
      name: "Báo cáo trợ cấp tuất nuôi dưỡng LTCM",
    },
    {
      path: "/nurture-subsidies-tkn",
      component: NurtureSubsidyReportCpn,
      perm: "",
      name: "Báo cáo trợ cấp tuất nuôi dưỡng TKN",
    },
    {
      path: "/nurture-subsidies-tb",
      component: NurtureSubsidyReportCpn,
      perm: "",
      name: "Báo cáo trợ cấp tuất nuôi dưỡng thương binh",
    },
    {
      path: "/nurture-subsidies-bb",
      component: NurtureSubsidyReportCpn,
      perm: "",
      name: "Báo cáo trợ cấp tuất nuôi dưỡng bệnh binh",
    },
    {
      path: "/nurture-subsidies-hh",
      component: NurtureSubsidyReportCpn,
      perm: "",
      name: "Báo cáo trợ cấp tuất nuôi dưỡng NHĐCM nhiễm CĐHH",
    },
    {
      path: "/nurture-subsidies-ls",
      component: NurtureSubsidyReportCpn,
      perm: "",
      name: "Báo cáo trợ cấp tuất nuôi dưỡng liệt sĩ",
    },
    {
      path: "/nurture-subsidies-ncc",
      component: NurtureSubsidyReportCpn,
      perm: "",
      name: "Báo cáo trợ cấp tuất nuôi dưỡng người có công",
    },
  ];

  return { adminRoutes };
}
